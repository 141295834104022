import {
    mapGetters,
    mapMutations
} from "vuex";
export default {
    computed: {
        ...mapGetters(['wxConfig', 'jsApiList', 'guideScenic'])
    },
    methods: {
        ...mapMutations(["setUserLocationMut"]),
        wechatSettings() {
            let that = this;
            console.log(this.wxConfig)
            wx.config(this.wxConfig);
            wx.ready(() => {
                //需在用户可能点击分享按钮前就先调用
                wx.checkJsApi({
                    jsApiList: this.jsApiList,
                });
                wx.updateAppMessageShareData({
                    title: this.guideScenic.name, // 分享标题
                    desc: this.guideScenic.intro, // 分享描述
                    link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: this.guideScenic.logo, // 分享图标
                });
                wx.updateTimelineShareData({
                    title: this.guideScenic.name, // 分享标题
                    link: window.location.href,
                    imgUrl: this.guideScenic.logo, // 分享图标
                });
                wx.getLocation({
                    type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'  
                    success: (res) => {
                        console.log(res);
                        that.setUserLocationMut([res.longitude, res.latitude]);
                    },
                    fail: (res) => {
                        console.log(res)
                    },
                });
            });
        },
    }
}