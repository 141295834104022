<template>
  <div class="audio-player">
    <div class="audio-player__action" v-if="showIcon">
      <van-image
        @click="handlePlay"
        :src="stateImage"
        :radius="playIconSize"
        :width="playIconSize"
        :height="playIconSize"
        fit="cover"
      />
    </div>
    <div class="audio-player__progress">
      <van-progress :percentage="percentage" track-color="#E5AE56" />
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Progress, Image as VanImage, Toast } from "vant";

Vue.use(Progress).use(VanImage).use(Toast);
export default {
  props: {
    src: String,
    playIconSize: {
      type: String,
      default: "0.75rem",
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      timer: null,
      audioSrc: null,
      duration: 1,
      percentage: 0,
      playing: false,
    };
  },
  computed: {
    stateImage() {
      if (this.playing) {
        return "/images/pause.png";
      } else {
        return "/images/play.png";
      }
    },
  },
  created() {
    this.audio.onloadedmetadata = () => {
      this.duration = this.audio.duration;
    };
    this.audio.onplay = () => {
      this.startTimer();
      this.playing = true;
    };
    this.audio.onpause = () => {
      this.stopTimer();
      this.playing = false;
    };
    this.audio.onended = () => {
      this.percentage = 100;
      this.$emit("ended");
      this.playing = false;
    };
    this.audio.onerror = (e) => {
      console.log("加载音频失败");
      console.log(e);
      this.$emit("error", e);
      this.playing = false;
    };
  },
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        if (this.audio && this.audio.readyState) {
          this.percentage = parseInt(
            (this.audio.currentTime * 100) / this.duration
          );
        }
      }, 500);
    },
    stopTimer() {
      clearInterval(this.timer);
    },
    stopMusic() {
      this.audio.pause();
      this.audio.currentTime = 0;
      this.percentage = 0;
      this.$emit("ended");
    },
    playMuisc() {
      if (this.audioSrc != this.src) {
        this.audioSrc = this.src;
        this.audio.src = this.audioSrc;
      }
      if (this.audioSrc) {
        this.audio.play();
      } else {
        Toast("没有音频文件");
      }
    },
    pausedMusic() {
      if (this.audioSrc && this.audioSrc == this.src) {
        this.audio.pause();
      } else {
        Toast("没有音频文件");
      }
    },
    handlePlay() {
      if (this.playing) {
        this.pausedMusic();
      } else {
        this.playMuisc();
      }
    },
  },
};
</script>