import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        wxConfig: {},
        jsApiList: [],
        guideScenic: {},
        categories: [],
        tapedSpot: {},
        userLocation: {},
        listMode: 'short'
    },
    getters: {
        listMode(state) {
            return state.listMode
        },
        userLocation(state) {
            return state.userLocation
        },
        isInited(state) {
            return state.jsApiList && state.jsApiList.length > 0
        },
        jsApiList(state) {
            return state.jsApiList
        },
        wxConfig(state) {
            return state.wxConfig
        },
        tapedSpot(state) {
            return state.tapedSpot
        },
        guideScenic(state) {
            return state.guideScenic
        },
        categories(state) {
            return state.categories
        },
        showCategory(state) {
            return state.guideScenic.enableCategory;
        },
        showMarkerIcon(state) {
            return state.guideScenic.showMarkerIcon;
        },
        showMarkerText(state) {
            return state.guideScenic.showMarkerText;
        },
        enableLines(state) {
            return state.guideScenic.enableLines;
        },
    },
    mutations: {
        setGuideScenicMut(state, payload) {
            state.guideScenic = payload;
        },
        setCategoriesMut(state, payload) {
            state.categories = payload
        },
        setTapedSpotMut(state, payload) {
            state.tapedSpot = payload
            if (payload) {
                state.listMode = 'spot'
            } else {
                state.listMode = 'short'
            }
        },
        setWxConfigMut(state, payload) {
            state.wxConfig = payload
        },
        setJsApiListMut(state, payload) {
            state.jsApiList = payload
        },
        setUserLocationMut(state, payload) {
            state.userLocation = payload
        },
        setListModeMut(state,payload){
            state.listMode = payload
        }
    },
    actions: {
        guideScenicAct(context, payload) {
            let {
                commit
            } = context;
            commit('setGuideScenicMut', payload)
        },
        categoriesAct(context, payload) {
            let {
                commit
            } = context;
            commit('setCategoriesMut', payload)
        },
        tapedSpotAct(context, payload) {
            let {
                commit
            } = context;
            commit('setTapedSpotMut', payload)
        },
        wxConfigAct(context, payload) {
            let {
                commit
            } = context;
            commit('setWxConfigMut', payload)
        },
        jsApiListAct(context, payload) {
            let {
                commit
            } = context;
            commit('setJsApiListMut', payload)
        }
    }
})

export default store