<template>
  <div class="guide-tabs">
    <div class="guide_tabs__box">
      <div class="guide-tabs__slot"><slot></slot></div>
      <div class="guide-tabs__swiper" ref="swiper">
        <div class="guide-tabs__line">
          <div
            class="guide-tabs__tab"
            v-for="(item, index) of items"
            :key="index"
            @click="handleClick(index)"
            :class="{ active: currentIndex == index }"
          >
            <div class="guide-tabs__title" v-if="currentIndex == index">
              <BgTitle>{{ item.name }}</BgTitle>
            </div>
            <div class="guide-tabs__title" v-else>
              <van-icon class="tab-icon" :name="$utils.appUrl(item.icon)" />{{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Icon } from "vant";
import BgTitle from "./bg-title.vue"

Vue.use(Icon);
export default {
  components: {
    BgTitle
},
  props: {
    items: Array,
  },
  data() {
    return {
      currentIndex: 0,
    };
  },
  methods: {
    handleClick(index) {
      this.currentIndex = index;
      let breakPoint = "80"; // (window.innerWidth * 1) / 3;
      let scrollSize = this.$refs.swiper.scrollWidth / this.items.length;
      this.$refs.swiper.scrollLeft =
        this.currentIndex * scrollSize - breakPoint;
      this.trigger();
    },
    trigger() {
      if (this.items[this.currentIndex])
        this.$emit(
          "selected",
          this.currentIndex,
          this.items[this.currentIndex]
        );
    },
    redo() {
      this.currentIndex = 0;
      this.trigger();
    },
  },
  mounted() {
    this.trigger();
  },
};
</script>

<style>
.tab-icon {
  width: 20px;
  height: 20px;
  display: flex !important;
  align-items: center;
  font-weight: bolder !important;
  justify-content: flex-end;
}
</style>