import {
    mapGetters,
    mapMutations
} from "vuex";

export default {
    computed: {
        ...mapGetters(['guideScenic', 'isInited', 'categories']),
        makerIcons() {
            let icons = {}
            for (let p of this.categories) {
                icons[p.id] = p;
            }
            return icons;
        }
    },
    methods: {
        ...mapMutations([
            "setGuideScenicMut",
            "setCategoriesMut", "setJsApiListMut", 'setWxConfigMut'
        ]),
        async initAppData(mid) {
            if (!this.isInited) {
                //let url = process.env.VUE_APP_BASE_URI+mid;
                let mapConfig = await this.$http.get(
                    `/api/front/guide/scenic/${mid}`, {
                    url: window.location.href
                }
                );
                this.setGuideScenicMut(mapConfig.guideScenic);
                this.setCategoriesMut(mapConfig.categories);
                if (mapConfig.guideScenic && mapConfig.guideScenic.wechatClient === true) {
                    this.setJsApiListMut(mapConfig.jsApiList);
                    this.setWxConfigMut(mapConfig.wxConfig);
                }
                return mapConfig.guideScenic
            } else {
                return this.guideScenic
            }
        },
        //绘制marker
        drawMarkers({
            markersData,
            activeIndex = null,
            enableVirtual = true,
            bindClick = true
        }) {
            let markers = markersData
                .filter((data) => {
                    //必须保证 lng lat 有值，否则导致高德地图报错 Pixel(N,N)错误
                    //return !!data.poi && data.poi.indexOf(",") != -1;
                    return this.$fn.strIsPoi(data.poi);
                })
                .map((markerData, index) => {
                    console.log("spot index: " + index)
                    return this.bulidLabelMark({
                        markerData,
                        isActive: activeIndex === index,
                        enableVirtual,
                        bindClick
                    });
                })
            this.map.add(markers);
            console.log(markersData)
            if (markersData.length === 0) {
                this.map.clearMap();
            }
            return markers;
        },
        //构建marker
        bulidLabelMark({
            markerData,
            isActive = false,
            enableVirtual = true,
            bindClick = true
        }) {
            let markerInfo = this.formatterMarker({
                spot: markerData,
                isActive,
                enableVirtual
            });
            let marker = new AMap.Marker(markerInfo);
            if (bindClick) {
                marker.on("click", () => {
                    //公共设施，比如卫生间，停车场等
                    this.setTapedSpotMut(markerData);
                    this.map.setCenter(this.$fn.str2poi(markerData.poi));
                    // if (markerData.isRedirect == true) {
                    //   window.location.assign(markerData.url);
                    // } else
                    if (markerData.popUp === false) {
                        this.handleNavi();
                    } else {
                        this.showSpotPopup = true;
                    }
                    //清除路线规划
                    this.clearWalkingGuide();
                });
            }
            return marker;
        },

        //spot格式化为marker
        formatterMarker({
            spot,
            isActive = false,
            enableVirtual = true
        }) {
            const {
                poi,
                isVirtual,
                virPoi,
                name,
                cateId,
                minZoom,
                maxZoom
            } = spot;
            let poiLngLat = [];
            if (enableVirtual && isVirtual && virPoi) {
                poiLngLat = this.$fn.str2poi(virPoi)
            } else {
                poiLngLat = this.$fn.str2poi(poi)
            }
            //minZoom maxZoom 必须有值，否则点位不可点击
            let zooms = [
                minZoom ? minZoom : this.minZoom,
                maxZoom ? maxZoom : this.maxZoom,
            ];
            let marker = {
                rawData: spot,
                title: name,
                position: poiLngLat,
                zooms: zooms,
                zIndex: 2,
                clickable: true,
                animation: "AMAP_ANIMATION_DROP",
            };

            marker.offset = isActive ? new AMap.Pixel(-24, -64) : new AMap.Pixel(-12, -32)

            let size = isActive ? [56, 60] : [
                this.guideScenic.markerIconWidth || 28,
                this.guideScenic.markerIconHeight || 30,
            ];
            console.log([
                this.guideScenic.markerIconWidth,
                this.guideScenic.markerIconHeight,
            ])
            marker.icon = new AMap.Icon({
                size: size,
                image: this.$utils.appUrl(this.makerIcons[cateId].marker),
                imageSize: size,
            });
            marker.label = {
                content: name,
                direction: this.guideScenic.markerTextPosition,
                //offset: new AMap.Pixel(0, -4)
            };
            return marker;
        },
    }
}
