<template>
  <div class="page-search">
    <default-navbar title="搜索"/>
    <van-search
        v-model="keyword"
        @search="handleSearch"
        placeholder="请输入搜索关键词"
    />
    <div class="search-results">
      <div class="search-list" v-if="list.length > 0">
        <van-cell-group>
          <van-cell
              v-for="(spot, index) of list"
              :key="index"
              @click.native="handleViewSpotDetail(spot.id)"
          >
            <template #title>
              <div class="line">
                <div class="line__pic">
                  <van-image
                      :src="$utils.appUrl(spot.mainPic)"
                      radius="6px"
                      class="main-pic"
                      fit="cover"
                  />
                </div>
                <div class="line__info">
                  <div class="line__title">
                    {{ spot.name }}
                  </div>
                  <div class="line__meta">
                    <div class="line__meta-item">
                      <van-icon name="expand"/>
                      里程:{{ calcDistance(spot) }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </van-cell>
        </van-cell-group>
      </div>
      <van-empty description="可以搜索民宿的名称关键词" v-else/>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import {Search, Cell, CellGroup, Empty} from "vant";

Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Search);
Vue.use(Empty);
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      keyword: "",
      list: [],
    };
  },
  computed: {
    ...mapGetters(["userLocation"]),
  },
  methods: {
    calcDistance(spot) {
      let poi = this.$fn.str2poi(spot.poi);
      return this.$utils.computeDis(this.userLocation, poi);
    },
    handleSearch(value) {
      this.$http
          .get('/api/front/spot/search', {
            keyWord: value,
          })
          .then((data) => {
            console.log(data);
            this.list = data;
          });
    },
    handleViewSpotDetail(id) {
      this.$router.push({
        name: "spot",
        params: {id: id},
      });
    },
  },
};
</script>

<style scoped>
.empty {
  padding: 15px;
  color: #666;
  text-align: center;
}
</style>
