<template>
    <div class="bg-title">
        <div class="bg-left"></div>
        <div class="bg-elem">
           <slot></slot>
        </div>
        <div class="bg-right"></div>
    </div>
</template>
<script>
export default {
    props: {
        width: {
            type: String,
            default: ''
        }
    }
}
</script>
<style lang="less">
.bg-title {
    display: flex;
    align-items: center;
    box-sizing: border-box;

    .bg-left {
        display: block;
        width: 14px;
        height: 24px;
        background-image: url(../assets/bg-title1.png);
        background-repeat: no-repeat;
        background-size: contain;
    }

    .bg-right {
        display: block;
        width: 14px;
        height: 24px;
        background-image: url(../assets/bg-title3.png);
        background-repeat: no-repeat;
        background-size: contain;
    }

    .bg-elem {
        display: block;
        height: 24px;
        background-image: url(../assets/bg-title2.png);
        background-repeat: repeat-x;
        background-size: contain;
        line-height: 24px;
        font-size: 14px;
        box-sizing: border-box;
        color: white;
    }
}
</style>