<template>
  <div class="guid-spot-short">
    <div class="guid-spot-short__container">
      <van-image
        :src="musicIcon"
        @click.native="handleMusic"
        :radius="playIconSize"
        :style="{ animationPlayState: animationPlayState }"
        class="main-pic"
        :width="playIconSize"
        :height="playIconSize"
        fit="cover"
      />
      <div class="guid-spot-short__info">
        <player :src="spot.audio" ref="player" @ended="handleEndMusic" :showIcon="false" />
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Icon, Image as VanImage } from "vant";
Vue.use(Icon).use(VanImage);
import player from "../components/audio-player.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    player,
  },
  props: {
    playIconSize:{
      type: String,
      default:'0.75rem'
    },
    spot: Object,
  },
  data() {
    return {
      animationPlayState: "paused",
    };
  },
  computed: {
    ...mapGetters([
      "guideScenic",
    ]),
    musicIcon() {
      if (this.animationPlayState == "paused") {
        return "/images/play.png";
      } else {
        return "/images/pause.png";
      }
    },
  },
  methods: {
    //播放语音讲解
    handleMusic() {
      if (this.animationPlayState == "paused") {
        this.animationPlayState = "running";
        this.$refs.player.playMuisc();
      } else {
        this.animationPlayState = "paused";
        this.$refs.player.pausedMusic();
      }
    },
    //暂停语音讲解
    handleEndMusic() {
      this.animationPlayState = "paused";
    },
  },
};
</script>