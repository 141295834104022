export default {
    str2poi(strpoi) {
        return strpoi.split(",").map(p => {
            return +p;
        });
    },
    strIsPoi(strpoi) {
        if (strpoi) {
            return typeof strpoi == 'string' && strpoi.indexOf(",") > 0;
        } else {
            return false
        }
    },
    humanDistance(m) {
        if (m < 1000) {
            return m + "米";
        } else {
            return (m / 1000).toFixed(2) + "公里";
        }
    },
    humanTime(s) {
        s = parseInt(s);
        if (s < 60) {
            return s + "秒";
        } else if (60 <= s && s < 3600) {
            return (s / 60).toFixed(0) + "分钟";
        } else {
            var mins = ((s % 3600) / 60).toFixed(0);
            var hours = (s / 3600).toFixed(0) + "小时";
            if (mins > 0) {
                return hours + mins + "分钟";
            } else {
                return hours;
            }
        }
    }
}