<template>
  <div>
    <!--    <guide-splash v-if="!showMap" @end="showMap = true"/>-->
    <div v-show="showMap" class="index-page">
      <guide-tabs ref="guidetabs" :items="categories" @selected="showFilterCategorySpot">
        <!-- <div class="flex-row">
          <weather :liveInfo="liveWeatherInfo" :forecasetInfo="forecasetInfo" />
          <div class="flex1">
            <van-search shape="round" @focus="gotoSearch" placeholder="请输入搜索关键词" />
          </div>
        </div> -->
      </guide-tabs>
      <div class="map-container">
        <div id="map-container"></div>
        <div class="controls top-right">
          <div class="ctr-btn" @click="ctrSwitchLayer">
            <van-icon name="/images/icon/leve.png" />
            <div class="name">图层</div>
          </div>
          <div class="ctr-btn" @click="ctrResetMapView">
            <van-icon name="/images/icon/refresh.png" />
            <div class="name">刷新</div>
          </div>
          <div class="ctr-btn">
            <div @click="ctrShowLines">
              <van-icon name="/images/icon/line.png" />
              <div class="name">路线</div>
            </div>
          </div>
          <div class="ctr-btn" v-if="vrUrl">
            <a :href="vrUrl" target="_blank">
              <van-icon name="/images/icon/pano.png" />
              <div class="name">全景</div>
            </a>
          </div>
          <div class="ctr-btn" @click="ctrLocationUser">
            <van-icon name="/images/icon/location.png" />
            <div class="name">定位</div>
          </div>
        </div>
        <!-- <div class="controls top-left">
          <zoom v-model="zoom" @change="handleZoomChange" />
        </div> -->
        <spots-list :spots="listSpots" @navi="queryNavigation"></spots-list>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import Vue from "vue";
import { Search, Icon, Popup, Dialog, Toast, Image as VanImage } from "vant";

Vue.use(Search).use(Icon).use(Popup).use(Dialog).use(Toast).use(VanImage);
//import zoom from "../components/map-zoom.vue";
import GuideTabs from "../components/guide-tabs.vue";
// import Weather from "../components/guide-weather.vue";
import SpotsList from "@/components/spots-list.vue";
import mixinsWechat from "@/libs/mixins-wechat";
import mixinsMap from "@/libs/mixins-map";

export default {
  mixins: [mixinsWechat, mixinsMap],
  components: {
    //zoom,
    GuideTabs,
    //Weather,
    SpotsList,
  },
  data() {
    return {
      showMap: true,
      map: null, //地图对象
      center: null, //地图的中心点
      weather: null, //天气对
      liveWeatherInfo: {},
      forecasetInfo: [],
      showTileLayer: true, //默认显示手绘图层
      geolocation: null, //用户定位信息
      tileLayer: null, //手绘地图层
      walkingGuide: null, //步行路径导航
      zoom:
        this.guideScenic && this.guideScenic.zoom ? this.guideScenic.zoom : 15,
      spots: [], //点位信息
      listSpots: [],
    };
  },
  computed: {
    ...mapGetters([
      "guideScenic",
      "categories",
      "enableLines",
      "showCategory",
      "showMarkerIcon",
      "showMarkerText",
    ]),
    city() {
      return this.guideScenic && this.guideScenic.city
        ? this.guideScenic.city
        : "";
    },
    url() {
      return this.guideScenic && this.guideScenic.url
        ? this.guideScenic.url
        : "";
    },
    vrUrl() {
      return this.guideScenic && this.guideScenic.vrUrl
        ? this.guideScenic.vrUrl
        : "";
    },
    isMiniProgram() {
      if (navigator.userAgent.indexOf("miniProgram") != -1) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapMutations(["setTapedSpotMut", "setUserLocationMut"]),
    gotoSearch() {
      this.$router.push({
        name: "search",
      });
    },
    async loadScenicSpots() {
      //console.log("loadScenicSpots:spots");
      //获取点位信息
      this.spots = await this.$http.get("/api/front/guide/spot/list", {
        id: this.guideScenic.id,
      });
      this.listSpots = this.spots.filter(spot => {
        return spot.isShowInfo
      })
      //console.log(this.spots);
      if (this.spots.length > 0) {
        let firstCateogry = this.categories[0];
        let markersData = this.spots.filter((spot) => {
          return spot.cateId === firstCateogry.id;
        });
        this.drawMarkers({
          markersData,
        });
      }
    },
    initMap() {
      this.map = new AMap.Map("map-container", {
        animateEnable: true,
        zoom: this.guideScenic.zoom,
        resizeEnable: true,
        center: this.center,
        // features: this.guideScenic.layers.split(','),
        zooms: [+this.guideScenic.minZoom, +this.guideScenic.maxZoom],
        lang: "zh_cn",
        //mapStyle: "amap://styles/macaron",// "amap://styles/52a15632b7130af7132869db532f2244", //"amap://styles/macaron",
      });
      this.map.on("zoomchange", () => {
        this.zoom = this.map.getZoom();
      });
      this.initTileLayer();
      this.map.on("complete", () => {
        console.log("map init completed");
        this.loadScenicSpots();
        this.setMapLimitBounds();
        this.initWalkingGuide();
        this.initUserLocation();
        this.initWeather();
      });
    },
    initWalkingGuide() {
      //初始化步行路径规划对象
      this.walkingGuide = new AMap.Walking({ map: this.map });
    },
    //初始化瓦片图层
    initTileLayer() {
      let tilePath = this.guideScenic.imageFormat;
      //如果开通不是http,则是本地路径
      if (!/^http/i.test(this.guideScenic.imageFormat)) {
        tilePath = "/" + this.guideScenic.imageFormat;
      }
      this.tileLayer = new AMap.TileLayer({
        getTileUrl: tilePath,
        opacity: 1,
        zIndex: 30,
        zooms: [+this.guideScenic.minZoom, +this.guideScenic.maxZoom], // 设置可见级别，[最小级别，最大级别]
      });
      this.map.add(this.tileLayer);
    },
    //初始化天气
    initWeather() {
      //console.log(this.city);
      let weatherClient = new AMap.Weather();
      weatherClient.getLive(this.city, (err, data) => {
        this.liveWeatherInfo = data;
        // console.log("weather info: " + JSON.stringify(data));
      });
      weatherClient.getForecast(this.city, (err, data) => {
        if (data.info === "OK") this.forecasetInfo = data.forecasts;
        //console.log("forecast info: " + JSON.stringify(data));
      });
    },
    //初始化用户定位信息
    initUserLocation() {
      //console.log("init user location callback");
      this.geolocation = new AMap.Geolocation({
        enableHighAccuracy: true, //是否使用高精度定位，默认:true
        timeout: 10000, //超过10秒后停止定位，默认：5s
        buttonPosition: "RB", //定位按钮的停靠位置
        panToLocation: false,
        buttonOffset: new AMap.Pixel(10, 20), //定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
        zoomToAccuracy: false, //定位成功后是否自动调整地图视野到定位点
        buttonDom: '<input hidden="true" />',
      });
      // 把定位插件加入地图实例
      this.map.addControl(this.geolocation);
      this.geolocation.on("error", (e) => {
        console.log("location error: " + JSON.stringify(e));
        //Toast.clear();
        //Dialog.alert({
        //  title: "提示",
        //  message: "定位用户位置失败",
        //});
        Toast("定位用户位置失败");
      }); //返回定位出错信息

      // 调用定位
      this.ctrLocationUser();
    },
    startLoading() {
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "加载中...",
      });
    },
    endLoading() {
      Toast.clear();
    },
    //必须是https的链接才可以正常使用
    getCurrentUserPosition(callback) {
      this.geolocation.getCurrentPosition((status, result) => {
        // console.log("user position: " + status);
        // console.log("user position: " + JSON.stringify(result));
        if (status == "complete") {
          let location = new AMap.LngLat(
            result.position.lng,
            result.position.lat
          );
          this.setUserLocationMut([result.position.lng, result.position.lat]);
          callback(location);
        } else {
          // Dialog.alert({
          //   title: "提示",
          //   message: "无法获取用户的位置信息",
          // });
          Toast("无法获取用户的位置信息");
        }
      });
    },
    //动态更新地图的显示层级
    handleZoomChange() {
      this.map.setZoom(this.zoom);
    },
    //判断用户是否在地图的显示范围，确认游客是否在景区
    userInScenic(location) {
      return this.map.getBounds().contains(location);
    },
    //设置地图显示的范围
    setMapLimitBounds() {
      let bottom = this.$fn.str2poi(this.guideScenic.lbPoi);
      let top = this.$fn.str2poi(this.guideScenic.rtPoi);
      let bounds = new AMap.Bounds(bottom, top);
      this.map.setLimitBounds(bounds);
    },
    resetMap() {
      //重置地图层级和中心点
      this.map.setZoomAndCenter(this.guideScenic.zoom, this.center);
      this.$refs["guidetabs"].redo();
    },
    //切换地图显示图层
    ctrSwitchLayer() {
      if (this.showTileLayer) {
        this.showTileLayer = false;
        this.tileLayer.hide();
        // this.map.setFeatures(["bg", "point", "road", "building"]);
      } else {
        this.showTileLayer = true;
        this.tileLayer.show();
        // this.map.setFeatures(["bg"]);
      }
    },
    //显示推荐路线
    ctrShowLines() {
      if (false && this.isMiniProgram) {
        wx.miniProgram.navigateTo({
          url: '/pages/page_home/shop_list/index?type=3',
        });
      } else {

        this.$router.push({
          name: "lines",
          params: { mid: this.$route.params.mid },
        });
      }
    },
    //重置地图
    ctrResetMapView() {
      this.resetMap();
    },
    //定位用户当前位置
    ctrLocationUser() {
      this.startLoading();
      this.getCurrentUserPosition((location) => {
        this.endLoading();
        if (this.userInScenic(location)) {
          this.map.setCenter(location);
        } else {
          Toast("用户不在景区");
          // Dialog.alert({
          //   title: "提示",
          //   message: "用户不在景区",
          // });
        }
      });
    },
    //清楚步行路线规划绘制的结果
    clearWalkingGuide() {
      if (this.walkingGuide) {
        this.walkingGuide.clear();
      }
    },
    //根据分类显示poi点位
    async showFilterCategorySpot(index, category) {
      console.log(index);
      this.cleanMapAllOverlay();

      console.log(this.spots);
      let spots = this.spots.filter((spot) => {
        return spot.cateId === category.id;
      });
      if (spots.length > 0) {
        this.showCategoryPopup = false;
        this.drawMarkers({ markersData: spots });
      } else {
        Toast("景区没有" + category.name);
      }
    },
    queryNavigation(tapedSpot) {
      this.startLoading();
      //this.cleanMapAllOverlay();
      this.getCurrentUserPosition((location) => {
        if (!this.userInScenic(location)) {
          if (this.guideScenic.enterPoi) {
            location = this.$fn.str2poi(this.guideScenic.enterPoi);
          } else {
            location = this.$fn.str2poi(this.guideScenic.centerPoi);
          }
        }
        this.walkingGuide.search(
          location,
          this.$fn.str2poi(tapedSpot.poi),
          (status, result) => {
            if (status === "complete") {
              console.log("绘制步行路线完成");
            } else {
              console.log("步行路线数据查询失败" + result);
            }
            this.endLoading();
          }
        );
      });
    },
    //清除地图的所有覆盖物
    cleanMapAllOverlay() {
      this.map && this.map.clearMap();
    },
  },
  async mounted() {
    console.log("index page mounted");
    let guideScenic = await this.initAppData(this.$route.params.mid);
    console.log(guideScenic);
    this.center = this.$fn.str2poi(guideScenic.centerPoi);
    console.log(this.center);
    this.initMap();
    if (guideScenic && guideScenic.wechatClient === true) {
      this.wechatSettings();
    }
  },
};
</script>
