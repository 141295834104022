<template>
  <div class="spots-list">
    <div class="spots-list__handler" v-show="showHandler" @click="changeView">
      
      <span v-if="showList" class="spots-list__bar"></span>
      <span v-else>查看列表</span>
    </div>
    <div class="spots-list__body" v-show="showList">
      <van-cell-group>
        <van-cell
          v-for="(spot, index) of spots"
          :key="index"
          is-link
          @click="handleClick(spot)"
        >
          <template #title>
            <div class="line">
              <div class="line__pic">
                <van-image
                  :src="$utils.appUrl(spot.mainPic)"
                  radius="6px"
                  class="main-pic"
                  fit="cover"
                />
              </div>
              <div class="line__info">
                <div class="line__title">
                  {{ spot.name }}
                </div>
                <div class="line__meta">
                  <div class="line__meta-item">
                    <van-icon name="expand" /> 里程:{{ calcDistance(spot) }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <div v-show="showSpot" class="spot-popup">
      <div class="spot-popup__container">
        <div class="spot-popup__spot-info">
          <spot-dailog
            @close="closeSpotPopup"
            @navi="queryNavigation"
          ></spot-dailog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import SpotDailog from "./spot-dialog.vue";
export default {
  components: {
    SpotDailog,
  },
  props: {
    spots: Array,
  },
  data() {
    return {
      showSpotPopup: true, //显示spot简要dialog
    };
  },
  computed: {
    ...mapGetters(["userLocation", "listMode"]),
    showList() {
      return this.listMode === "list";
    },
    showHandler() {
      return this.listMode === "short" || this.listMode === "list";
    },
    showSpot() {
      return this.listMode === "spot";
    },
  },
  methods: {
    ...mapMutations(["setTapedSpotMut", "setListModeMut"]),
    calcDistance(spot) {
      if(this.$fn.strIsPoi(spot.poi)) {
        let poi = this.$fn.str2poi(spot.poi);
        return this.$utils.computeDis(this.userLocation, poi);
      } else {
        return '没有提供坐标'
      }
    },
    //关闭景点详情对话框
    closeSpotPopup() {
      this.setTapedSpotMut(null);
    },
    queryNavigation(tapedSpot) {
      this.$emit("navi", tapedSpot);
    },
    changeView() {
      if (this.listMode === "list") {
        this.setListModeMut("short");
      } else {
        this.setListModeMut("list");
      }
    },
    handleClick(spot) {
      this.setTapedSpotMut(spot);
    },
  },
};
</script>

<style>
.spots-list {
  position: fixed;
  bottom: 0;
  width: 100%;
}
.spots-list__handler {
  padding: 15px;
  text-align: center;
  background-color: white;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.spots-list__bar {
  display: inline-block;
  width: 60px;
  border-radius: 20px;
  height: 5px;
  content: ' ';
  background-color:#E5AE56;
}
.spots-list__body {
  background-color: white;
  max-height: 300px;
  overflow-y: scroll;
}
</style>