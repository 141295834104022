<template>
  <div class="spot-dailog">
    <div class="spot-dailog__container">
      <div class="spot-dailog__header">
        <van-image :src="$utils.appUrl(tapedSpot.mainPic)" radius="0.25rem" class="spot-dailog__pic" width="1.25rem"
          height="1.25rem" fit="cover" />
        <div class="spot-dailog__info">
          <div class="spot-dailog__title">
            {{ tapedSpot.name }}
          </div>
          <div class="spot-dailog__has-audio">
            <p><van-icon name="location-o" /> {{ distance }}</p>
          </div>
        </div>
        <div class="spot-dailog__close" @click="handleClose">
          <van-image src="/images/close.png" width="30px" height="30px" fit="cover" />
        </div>
      </div>
      <div class="spot-dailog__player" v-if="tapedSpot.audio">
        <player :src="$utils.appUrl(tapedSpot.audio)" ref="player" />
      </div>
      <div class="spot-dailog__actions">
        <!-- <text-icons>
          <text-icon text="关闭" @click.native="handleClose">
            <van-icon name="close" />
          </text-icon>
          <text-icon text="导航" @click.native="handleNavi">
            <van-icon name="location-o" />
          </text-icon>
          <text-icon text="详情" @click.native="handleViewSpotDetail">
            <van-icon name="photo-o" />
          </text-icon>
        </text-icons> -->
        <div class="spot-dailog__buttons">
          <!-- <image-container @click.native="handleNavi">导航</image-container>
          <div v-if="tapedSpot.isShowInfo">
            <image-container @click.native="handleViewSpotDetail"
              >详情</image-container>
          </div> -->
          <BgTitle @click.native="handleNavi">导航</BgTitle>
          <BgTitle @click.native="handleViewSpotDetail" v-if="tapedSpot.isShowInfo" >详情</BgTitle>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//spotType  代表点位是否有详情页面
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import { Toast, Image as VanImage } from "vant";
Vue.use(Toast).use(VanImage);

import player from "./audio-player.vue";
// import textIcons from "./text-icons.vue";
// import textIcon from "./text-icon.vue";
// import ImageContainer from "./image-container.vue";
import BgTitle from "./bg-title.vue";
export default {
  components: {
    player,
    // textIcon,
    // textIcons,
    // ImageContainer,
    BgTitle
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["tapedSpot", "userLocation"]),
    distance() {
      if (this.tapedSpot.poi) {
        let poi = this.$fn.str2poi(this.tapedSpot.poi);
        return this.$utils.computeDis(this.userLocation, poi);
      }
      return "暂无信息";
    },
    isMiniProgram() {
      if (navigator.userAgent.indexOf("miniProgram") != -1) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapMutations(["setTapedSpotMut"]),
    openWechatLocation() {
      let poi = this.$fn.str2poi(this.tapedSpot.poi);
      wx.openLocation({
        longitude: poi[0],
        latitude: poi[1],
        name: this.tapedSpot.name,
        address: this.tapedSpot.address,
        scale: 15,
      });
    },
    handleClose() {
      if (this.$refs.player) {
        this.$refs.player.stopMusic();
      }
      this.setTapedSpotMut({}), this.$emit("close");
    },
    //查询导航路线
    handleNavi() {
      if (this.$utils.isWechat()) {
        this.openWechatLocation();
      } else {
        this.$emit("navi", this.tapedSpot);
      }
    },
    //打开景点详情
    handleViewSpotDetail() {
      if (this.isMiniProgram) {
        wx.miniProgram.navigateTo({
          url: `/pages/page_home/shop_list/spot-detail?id=${this.tapedSpot.id}`,
          fail() {
            Toast('打开小程序页面失败')
          }
        });
      } else {
        this.$router.push({
          name: "spot",
          params: { id: this.tapedSpot.id },
        });
      }
    },
  },
};
</script>
