<template>
  <div class="page-lines">
    <default-navbar title="推荐路线" />
    <div class="lines">
      <van-cell-group>
        <van-cell v-for="(line, index) of lines" :key="index" is-link :to="{
          name: 'line',
          params: { mid: $route.params.mid, id: line.lineId },
        }">
          <template #title>
            <div class="line">
              <div class="line__pic">
                <van-image :src="$utils.appUrl(line.pic)" radius="6px" class="main-pic" fit="cover" />
              </div>
              <div class="line__info">
                <div class="line__title">
                  {{ line.name }}
                </div>
                <div class="line__meta">
                  <div class="line__meta-item">
                    <van-icon name="expand" />
                    景点:{{ line.guideSpotVos.length }}个
                  </div>
                </div>
                <div class="line__meta">
                  <div class="line__meta-item">
                    <van-icon name="expand" />
                    里程:{{
                      $fn.humanDistance(line.distance)
                    }}
                  </div>
                </div>
                <div class="line__meta">
                  <div class="line__meta-item">
                    <van-icon name="clock" />
                    时长:{{
                      $fn.humanTime(line.time)
                    }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Cell, CellGroup, Dialog } from "vant";

Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Dialog);
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      lines: []
    };
  },
  computed: {
    ...mapGetters(["guideScenic"]),
  },
  methods: {
    loadLines() {
      //获取路线信息
      this.$http.get('/api/front/guide/line/list/' + this.$route.params.mid).then((data) => {
        this.lines = data;
      });
    },
  },
  mounted() {
    this.loadLines();
  },
};
</script>
