<template>
  <div class="page-line">
    <default-navbar :title="title" />
    <div id="map-line"></div>
    <div class="spot-swiper">
      <van-swipe
        :show-indicators="false"
        width="300"
        :loop="false"
        @change="handleChange"
      >
        <van-swipe-item v-for="(spot, index) of spots" :key="index">
          <div class="spot-item">
            <div class="spot-item__body">
              <img
                class="spot-item__image"
                width="40px"
                height="40px"
                :src="$utils.appUrl(spot.mainPic)"
              />
              <div class="spot-item__info">
                <h4>{{ spot.name }}</h4>
                <div class="spot-item__intro">
                  {{ spot.intro }}
                </div>
              </div>
            </div>
            <div class="spot-item__footer">
              <div class="spot-item__button" @click="handleNavi(spot)">
                <image-container>到这里去</image-container>
              </div>
              <div class="spot-item__button" @click="handleRoute(spot)">
                <image-container>查看详情</image-container>
              </div>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Swipe, SwipeItem, Image as VanImage, Toast } from "vant";

Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(VanImage);
Vue.use(Toast);
import imageContainer from "@/components/image-container.vue";
import mixinsWechat from "@/libs/mixins-wechat";
import mixinsMap from "@/libs/mixins-map";
export default {
  mixins: [mixinsWechat, mixinsMap],
  components: {
    imageContainer,
  },
  data() {
    return {
      title: "路线详情",
      map: null, //地图对象
      center: null, //地图的中心点
      walkingPolyline: null, //推荐路线规划
      spots: [],
      markers: [],
    };
  },
  computed: {
    isMiniProgram() {
      if (navigator.userAgent.indexOf("miniProgram") != -1) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    initMap() {
      this.map = new AMap.Map("map-line", {
        animateEnable: true,
        zoom: this.guideScenic.zoom,
        resizeEnable: true,
        center: this.center,
        features: ["bg", "road"],
        zooms: [+this.guideScenic.minZoom, +this.guideScenic.maxZoom],
        lang: "zh_cn",
      });
      this.map.on("complete", () => {
        this.$http
          .get(`/api/front/spot/line/${this.$route.params.id}`)
          .then((line) => {
            this.title = line.name;
            this.spots = line.guideSpotVos;
            this.drawGuideLine(line);
          });
      });
    },
    initPolyline() {
      if (!this.walkingPolyline) {
        this.walkingPolyline = new AMap.Polyline({
          isOutline: true,
          outlineColor: "#ffeeee",
          borderWeight: 2,
          strokeWeight: 6,
          strokeColor: "#0091ff",
          lineJoin: "round",
          showDir: true,
        });
      }
      this.walkingPolyline.setMap(this.map);
    },
    //绘制推荐路线
    drawGuideLine(line) {
      this.initPolyline();
      var startMarker = new AMap.Marker({
        position: line.origin,
        icon: "https://webapi.amap.com/theme/v1.3/markers/n/start.png",
        map: this.map,
      });

      var endMarker = new AMap.Marker({
        position: line.destination,
        icon: "https://webapi.amap.com/theme/v1.3/markers/n/end.png",
        map: this.map,
      });
      this.walkingPolyline.setPath(line.path);

      this.markers = this.drawMarkers({
        markersData: line.guideSpotVos,
        bindClick: false,
        enableVirtual: false,
        activeIndex: 0,
      });
      this.map.setFitView([startMarker, endMarker, this.walkingPolyline]);
    },
    handleChange(index) {
      this.map.remove(this.markers);
      this.markers = this.drawMarkers({
        markersData: this.spots,
        bindClick: false,
        enableVirtual: false,
        activeIndex: index,
      });
      let lngLat = this.$fn.str2poi(this.spots[index].poi);

      this.map.setCenter(lngLat);
    },
    openWechatLocation(spot) {
      let poi = this.$fn.str2poi(spot.poi);
      wx.openLocation({
        longitude: poi[0],
        latitude: poi[1],
        name: spot.name,
        address: spot.address,
        scale: 15,
      });
    },
    handleNavi(spot) {
      if (this.$utils.isWechat()) {
        this.openWechatLocation(spot);
      } else {
        Toast("请在微信中打开");
      }
    },
    handleRoute(spot) {
      if (this.isMiniProgram) {
        wx.miniProgram.navigateTo({
          url: `/pages/scenic/spot?id=${spot.spotId}`,
        });
      } else {
        this.$router.push({
          name: "spot",
          params: { mid: this.$route.params.mid, id: spot.spotId },
        });
      }
    },
  },
  async mounted() {
    let guideScenic = await this.initAppData(this.$route.params.mid);
    this.center = this.$fn.str2poi(guideScenic.centerPoi);
    this.initMap();
    if (guideScenic && guideScenic.wechatClient === true) {
      this.wechatSettings();
    }
  },
};
</script>

<style lang="less">
#map-line {
  height: calc(100vh - 46px);
}
.spot-swiper {
  position: fixed;
  bottom: 1rem;
  width: 100%;
}
</style>
