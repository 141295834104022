import Vue from 'vue'
import http from './libs/http.js';
import utils from './libs/utils.js';
import fn from "./fn.js";
import store from './store'
import router from './router';
import App from './App.vue'
import DefaultNavbar from './components/default-navbar.vue';
import {
  Lazyload
} from 'vant';
import 'vant/lib/index.css';
import "./less/main.less";
Vue.config.productionTip = false

Vue.prototype.$http = http;
Vue.prototype.$utils = utils;
Vue.prototype.$fn = fn;
Vue.prototype.audio = new Audio();

Vue.use(Lazyload, {
  lazyComponent: true,
});
Vue.component('default-navbar', DefaultNavbar)
Vue.config.ignoredElements = ['wx-open-launch-weapp']
//utils.resetRem()
new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
