 const compress = (image, maxH = 160) => {
   return new Promise(function (resolve, reject) {
     try {
       let img = new Image();
       img.onload = () => {
         let cvs = document.createElement('canvas'),
           ctx = cvs.getContext('2d');
         if (img.height > maxH) {
           img.width *= maxH / img.height;
           img.height = maxH;
         }
         cvs.width = img.width;
         cvs.height = img.height;
         ctx.clearRect(0, 0, cvs.width, cvs.height);
         ctx.drawImage(img, 0, 0, img.width, img.height);
         cvs.toBlob((data) => {
           resolve(data)
         }, 'image/jpeg', 0.6)
       }
       img.src = image;
     } catch (e) {
       console.log(e);
       reject(new Error(e.getMessage()));
     }
   });
 }

 const kmFormat = (distance) => {
   if (distance < 1000)
     return distance + "米";
   else if (distance > 1000)
     return (Math.round(distance / 100) / 10).toFixed(1) + "公里";
 }

 const secondFormat = (value) => {
   var theTime = parseInt(value); // 需要转换的时间秒 
   var theTime1 = 0; // 分 
   var theTime2 = 0; // 小时 
   var theTime3 = 0; // 天
   if (theTime > 60) {
     theTime1 = parseInt(theTime / 60);
     theTime = parseInt(theTime % 60);
     if (theTime1 > 60) {
       theTime2 = parseInt(theTime1 / 60);
       theTime1 = parseInt(theTime1 % 60);
       if (theTime2 > 24) {
         //大于24小时
         theTime3 = parseInt(theTime2 / 24);
         theTime2 = parseInt(theTime2 % 24);
       }
     }
   }
   var result = '';
   if (theTime > 0) {
     result = "" + parseInt(theTime) + "秒";
   }
   if (theTime1 > 0) {
     result = "" + parseInt(theTime1) + "分" + result;
   }
   if (theTime2 > 0) {
     result = "" + parseInt(theTime2) + "小时" + result;
   }
   if (theTime3 > 0) {
     result = "" + parseInt(theTime3) + "天" + result;
   }
   return result;
 }

 const iconWeatherMap = {
   '风': ['有风', '平静', '微风', '和风', '清风', '强风/劲风', '疾风', '大风', '烈风', '风暴', '狂爆风', '飓风', '热带风暴', '龙卷风'],
   '多云': ['少云', '晴间多云', '多云'],
   '雪': ['雪', '阵雪', '小雪', '中雪', '大雪', '暴雪', '小雪-中雪', '中雪-大雪', '大雪-暴雪', '冷'],
   '雾': ['浮尘', '扬沙', '沙尘暴', '强沙尘暴', '雾', '浓雾', '强浓雾', '轻雾', '大雾', '特强浓雾'],
   '晴': ['晴', '热'],
   '雨夹雪': ['雨雪天气', '雨夹雪', '阵雨夹雪'],
   '雨': ['阵雨', '雷阵雨', '雷阵雨并伴有冰雹', '小雨', '中雨', '大雨', '暴雨', '大暴雨', '特大暴雨', '强阵雨', '强雷阵雨', '极端降雨', '毛毛雨/细雨', '雨', '小雨-中雨', '中雨-大雨', '大雨-暴雨', '暴雨-大暴雨', '大暴雨-特大暴雨', '冻雨'],
   '阴': ['阴', '霾', '中度霾', '重度霾', '严重霾', '未知']
 }

 const humanDistance = (m) => {
   if (m < 1000) {
     return m + "米";
   } else {
     return (m / 1000).toFixed(2) + "公里";
   }
 }

 const resetFontSize = () => {
  var html = document.querySelector("html");
  var oWidth = document.body.clientWidth || document.documentElement.clientWidth;
  console.log(oWidth)
  var size = 37.5
  if (oWidth > 750) {
      size = 50
  }
  html.setAttribute('style', 'font-size:' + size.toFixed(2) + "px")
  document.body.style.display = 'block';
}
const resetRem = () => {
  resetFontSize();
  var resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize';
  window.onload = resetFontSize;
  window.addEventListener(resizeEvt, resetFontSize, false);
}

 export default {
   compress,
   secondFormat,
   kmFormat,
   resetRem,
   iconWeatherMap,
   isWechat() {
     let rs = navigator.userAgent.toLowerCase().match(/micromessenger/i)
     return  rs !== null
   },
   appUrl(url) {
     if (url) {
       if (url.startsWith('http')) {
         return url;
       }
       return process.env.VUE_APP_BASE_HOST + url;
     }
     return url;
   },
   //计算距离 高德地图api
   computeDis(p1, p2) {
     var distance = Math.round(AMap.GeometryUtil.distance(p1, p2));
     return "距离约" + humanDistance(distance);
   },
   weatherIcon(name) {
     for (let p in iconWeatherMap) {
       if (iconWeatherMap[p].indexOf(name) > -1) {
         return p;
       }
     }
     return null;
   }
 }