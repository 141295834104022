import Vue from 'vue';
import VueRouter from 'vue-router'
import HomePage from "./pages/index-page";
import SpotPage from "./pages/spot-page";
import LinesPage from "./pages/lines-page";
import LinePage from "./pages/line-page";
import SearchPage from "./pages/search-page"

Vue.use(VueRouter);
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}
let router = new VueRouter({
    // base: '/senshanmap/',
    mode: 'history',
    routes: [{
        path: '/:mid/',
        name: 'home',
        component: HomePage,
        meta: {
            title: '景区导览',
            keepAlive: true,
        }
    },
        {
            path: '/:mid/spot/:id',
            name: 'spot',
            component: SpotPage,
            meta: {
                title: '景点详情',
                keepAlive: false,
            }
        },
        {
            path: '/:mid/lines',
            name: 'lines',
            component: LinesPage,
            meta: {
                title: '推荐线路',
                keepAlive: true,
            }
        },
        {
            path: '/:mid/line/:id',
            name: 'line',
            component: LinePage,
            meta: {
                title: '推荐线路导览',
                keepAlive: false,
            }
        },
        {
            path: '/:mid/search',
            name: 'search',
            component: SearchPage,
            meta: {
                title: '搜索',
                keepAlive: true,
            }
        },

    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {
                x: 0,
                y: 0
            }
        }
    }
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next()
})

router.afterEach(() => {
    Vue.prototype.audio.pause();
})

export default router
