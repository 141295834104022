<template>
  <div class="spot-detail-info">
    <default-navbar fixed />
    <div class="spot-detail-info__content" v-if="item">
      <div class="spot-detail-info__images">
        <div class="spot-detail-info__swiper">
          <van-swipe :autoplay="5000">
            <van-swipe-item v-for="(image, index) in images" :key="index" :lazy-rende="true">
              <van-image :src="image" class="spot-detail-info__main-pic" :lazy-load="true" fit="cover" />
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>
      <div class="spot-detail-info__detail-info">
        <div class="spot-detail-info__float-title">
          <h2 class="spot-detail-info__title">
            {{ item.name }}
          </h2>
          <div class="spot-detail-info__address">
            <van-icon name="location-o" /> {{ item.address || "-" }}
          </div>
        </div>
        <div class="spot-detail-info__player">
          <spot-short :spot="item" />
        </div>
        <div class="spot-detail-info__detail" v-html="item.detail"></div>
      </div>
      <van-goods-action>
        <div class="action-button" @click="handleNavi">
          <div>导航</div>
        </div>
        <div class="action-button" v-if="hasOuterUrl">
          <a :href="item.url"> {{ item.siteText || "立即预定" }} </a>
        </div>
      </van-goods-action>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import SpotShort from "../components/spot-short.vue";
import {
  Button,
  Image as VantImage,
  Swipe,
  SwipeItem,
  Dialog,
  GoodsAction,
  Icon,
  Toast,
} from "vant";
Vue.use(VantImage).use(Button).use(Swipe).use(SwipeItem).use(Dialog);
Vue.use(Icon);
Vue.use(GoodsAction);
Vue.use(Toast);
import mixinsWechat from "@/libs/mixins-wechat";
export default {
  mixins: [mixinsWechat],
  components: {
    SpotShort
  },
  data() {
    return {
      item: {},
      showDetail: false,
    };
  },
  computed: {
    hasOuterUrl() {
      return this.item.redirect;
    },
    images() {
      let images = new Array();
      if (this.item) {
        if (this.item.mainPic) {
          images.push(this.$utils.appUrl(this.item.mainPic));
        }
        if (this.item.album) {
          const album = JSON.parse(this.item.album);
          album.forEach((img) => {
            if (img) {
              images.push(this.$utils.appUrl(img));
            }
          });
        }
      }
      return images;
    },
  },
  methods: {
    loadSpot() {
      const id = this.$route.params.id;
      this.$http.get(`/api/front/spot/info/${id}`).then((data) => {
        this.item = data;
      });
    },
    openWechatLocation() {
      let poi = this.$fn.str2poi(this.item.poi);
      wx.openLocation({
        longitude: poi[0],
        latitude: poi[1],
        name: this.item.name,
        address: this.item.address,
        scale: 15,
      });
    },
    handleNavi() {
      if (this.$utils.isWechat()) {
        this.openWechatLocation();
      } else {
        Toast("请在微信中打开");
      }
    },
  },
  watch: {
    $route() {
      this.loadSpot();
      this.wechatSettings();
    },
  },
  mounted() {
    this.loadSpot();
  },
};
</script>
<style lang="less" scoped>
.action-button {
  background-image: url("../assets/button-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  display: block;
  height: 40px;
  width: 100%;
  text-align: center;
  line-height: 40px;
  margin: 30px;
  box-sizing: border-box;
  color: white;

  a {
    color: white;
  }
}
</style>
